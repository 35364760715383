import {Badge} from "react-bootstrap";

const Header = () => {
    return <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
        <a href="/" id={"top"}
           className="d-flex align-items-center  mb-md-0 me-md-auto m-sm-0 link-body-emphasis text-decoration-none">
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <img src={"/assets/images/icon.png"} style={{width: "20px"}} className={"mx-2"} alt={"Rapid Free Image Share"} />
            <span className="fs-4">Image Share</span>
            <small style={{marginLeft: "-40px", marginTop: "25px"}}><Badge bg="warning" text="dark">beta</Badge></small>
        </a>

        <ul className="nav nav-pills">
            <li className="nav-item"><a href="/" className="nav-link active" aria-current="page">Home</a></li>
            <li className="nav-item"><a href="https://rpd.ink/" target={"_blank"} rel="noreferrer" className="nav-link">Link Shortener</a></li>
            <li className="nav-item d-none"><a href="#top" className="nav-link">Pricing</a></li>
            <li className="nav-item d-none"><a href="#top" className="nav-link">FAQs</a></li>
            <li className="nav-item d-none"><a href="#top" className="nav-link">About</a></li>
        </ul>
    </header>;
}

export default Header;
