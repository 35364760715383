import React, {useState} from 'react';
import {Button, Card, Col, InputGroup, Row, Stack} from "react-bootstrap";
import Loading from "../_layout/Loading";
import "../_layout/Loading.css";
import Config from "../../Config"
import {toast} from 'react-toastify';

const ImageUpload = () => {
    const [file, setFile] = useState(null);
    const [timeoutValue, setTimeoutValue] = useState(0);
    const [noteValue, setNoteValue] = useState("");
    const [uploadedImageUrl, setUploadedImageUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [urls, setUrls] = useState({
        view: "",
        direct: "",
        bbcode: "",
        html: ""
    });

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const handleFocus = (event) => event.target.select();

    const handleTimeoutChange = (event) => {
        const value = event.target.value;
        setTimeoutValue(value);
    };
  const handleNoteChange = (event) => {
        const value = event.target.value;
      // Girilen metni 255 karaktere sınırla

      if (value.length <= 255) {
          setNoteValue(value);
      } else {
          // 255 karakterden fazla ise son 255 karakteri al
          setNoteValue(value.slice(0,255));
      }
    };

    const handleSubmit = async (event) => {
        if(!file) {
            toast.error("Select file");
            return;
        }
        event.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('image', file);
        formData.append('note', noteValue);
        try {
            const response = await fetch(`${Config.api_base}/api/image/save?timeout=${timeoutValue}`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                const urls = {
                    view: `${Config.view_base}/${data.id}`,
                    direct: `${Config.image_base}/image/direct/${data.id}`,
                    bbcode: `[url=${Config.view_base}/${data.id}][img]${Config.image_base}/image/direct/${data.id}[/img][/url]`,
                    bbcode_thumb: `[url=${Config.view_base}/${data.id}][img]${Config.image_base}/image/direct/${data.id}/thumb[/img][/url]`,
                    html: `${Config.view_base}/${data.id}`,
                    full_html: `<a href="${Config.view_base}"><img src="${Config.image_base}/image/direct/${data.id}" alt="Random" border="0"></a>`,

                }
                setUrls(urls);
                setUploadedImageUrl(data.display_url);
                setNoteValue("");
                toast("Image Uploaded");
            } else {
                throw new Error('Network response was not ok');
            }
            setLoading(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };


// your function to copy here

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            toast("Copied !");
            //setCopySuccess('Copied!');
        } catch (err) {
            toast.error("Cannot copied");
            //setCopySuccess('Copy');
        }
    };

    return (
        <>
            {uploadedImageUrl && (
                <Row>
                    <Col md={8} sm={12} className={"offset-sm-0  offset-md-2"}>
                        <Card className={"shadow"}>
                            <Card.Header className={"shadow-sm"}><h3>Uploaded Image</h3></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col className={"text-center"}>
                                        <img src={uploadedImageUrl} alt="Uploaded"
                                             className={"img-fluid rounded rounded-2"}/>
                                    </Col>
                                    <Col>
                                        <Stack gap={2} direction={"vertical"} className={"align-items-start"}>
                                            <label className={"w-100"}>
                                                <small>View Url</small>
                                                <InputGroup className="mb-3">
                                                    <Button variant="outline-success"
                                                            onClick={() => copyToClipBoard(urls.view)}>
                                                        Copy
                                                    </Button>
                                                    <input type="text" readOnly={true} value={urls.view}
                                                           onFocus={handleFocus}
                                                           onClick={() => copyToClipBoard(urls.view)}
                                                           className={"form-control form-control-sm"}/>
                                                </InputGroup>
                                            </label>
                                            <label className={"w-100"}>
                                                <small>Direct Link</small>
                                                <InputGroup className="mb-3">
                                                    <Button variant="outline-success"
                                                            onClick={() => copyToClipBoard(urls.direct)}>
                                                        Copy
                                                    </Button>
                                                    <input type="text" readOnly={true} value={urls.direct}
                                                           onFocus={handleFocus}
                                                           onClick={() => copyToClipBoard(urls.view)}
                                                           className={"form-control form-control-sm"}/>
                                                </InputGroup>
                                            </label>
                                            <label className={"w-100"}>
                                                <small>BBCode</small>
                                                <InputGroup className="mb-3">
                                                    <Button variant="outline-success"
                                                            onClick={() => copyToClipBoard(urls.bbcode)}>
                                                        Copy
                                                    </Button>
                                                    <input type="text" readOnly={true} value={urls.bbcode}
                                                           onFocus={handleFocus}
                                                           onClick={() => copyToClipBoard(urls.bbcode)}
                                                           className={"form-control form-control-sm"}/>
                                                </InputGroup>
                                            </label>
                                            <label className={"w-100"}>
                                                <small>BBCode Thumb</small>
                                                <InputGroup className="mb-3">
                                                    <Button variant="outline-success"
                                                            onClick={() => copyToClipBoard(urls.bbcode_thumb)}>
                                                        Copy
                                                    </Button>
                                                    <input type="text" readOnly={true} value={urls.bbcode_thumb}
                                                           onFocus={handleFocus}
                                                           onClick={() => copyToClipBoard(urls.bbcode_thumb)}
                                                           className={"form-control form-control-sm"}/>
                                                </InputGroup>
                                            </label>
                                            <label className={"w-100"}>
                                                <small>Full Html</small>
                                                <InputGroup className="mb-3">
                                                    <Button variant="outline-success"
                                                            onClick={() => copyToClipBoard(urls.full_html)}>
                                                        Copy
                                                    </Button>
                                                    <input type="text" readOnly={true} value={urls.full_html}
                                                           onFocus={handleFocus}
                                                           onClick={() => copyToClipBoard(urls.full_html)}
                                                           className={"form-control form-control-sm"}/>
                                                </InputGroup>

                                            </label>
                                            <a href={urls.view}>View Image</a>
                                        </Stack>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}

            <Row className={"mt-4"}>
                <Col md={8} sm={12} className={"offset-sm-0  offset-md-2"}>
                    <Card className={"shadow"}>
                        <Card.Header><h3>Upload Image</h3></Card.Header>
                        <Card.Body>
                            {loading ? <Loading/> :
                                <Row>
                                    <Col>
                                        <Stack gap={2} direction={"horizontal"} className={""}>
                                            <label>
                                                <small>Select an image:</small>
                                                <input type="file" className={"form-control"} accept="image/*"
                                                       onChange={handleFileChange}/>
                                            </label>

                                            <label>
                                                <small>Delete at*:</small>
                                                <select className={"form-select"} value={timeoutValue}
                                                        disabled={false}
                                                        onChange={handleTimeoutChange}
                                                        defaultValue={0}
                                                >
                                                    <option value={0}>Never</option>
                                                    <option value={1}>1 Min</option>
                                                    <option value={5} >5 Min</option>
                                                    <option value={10}>10 Min</option>
                                                    <option value={15}>15 Min</option>
                                                    <option value={30}>30 Min</option>
                                                    <option value={60}>1 Hour</option>
                                                    <option value={120}>2 Hour</option>
                                                    <option value={300}>5 Hour</option>
                                                </select>
                                            </label>
                                            <label>
                                                <small>Note:</small>
                                                <input className={"form-control"} value={noteValue} maxLength={500}
                                                        onChange={handleNoteChange}
                                                />
                                            </label>
                                            <button type="button" onClick={handleSubmit}
                                                    className={"btn btn-success mt-4"}>Submit
                                            </button>
                                        </Stack>
                                    </Col>
                                </Row>
                            }


                        </Card.Body>
                        <Card.Footer>
                            <small className={"text-muted"}>* This is beta test, automatic delete timer fixed to 1 hour
                                for a while. </small>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>

        </>
    );
};

export default ImageUpload;
