import React from "react"
import {Col, Container, Row} from "react-bootstrap";
import ImageUpload from "./ImageUploader";

function Home() {
    return (
        <Container>
            <Row>
                <Col className={"text-center"}>
                    <h1>Upload and share your images.</h1>
                    <p>Start uploading your images now. 32 MB limit. Direct image links, BBCode and HTML thumbnails.</p>

                </Col>
            </Row>
                <ImageUpload />
        </Container>
    )
}

export default Home
