import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./component/Home";
import './App.css';
import Header from "./component/_layout/Header";
import View from "./component/View";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

    return (
        <>
            <Header/>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/:id" element={<View />} />
                </Routes>
            </BrowserRouter>
            <ToastContainer />

        </>
    )
}

export default App;
