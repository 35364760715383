import React, {useEffect, useState} from "react"
import {Col, Container, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import axios from "axios";
import "../_layout/Loading.css";
import Config from "../../Config"
import Comment from "../Comment/index";
import Comments from "../Comment/Comments";

function View() {
    const [image, setImage] = useState("/assets/images/spinner.svg");
    const {id} = useParams();
    const [note, setNote] = useState("");
    const [guestNum, setGuestNum] = useState(0);
    const [ttl, setTtl] = useState(3600);
    const [comments, setComments] = useState({id, ttl, comments: []});
    const [refreshComments, setRefreshComments] = useState(false);
    const askView = async () => {
        try {
            //setLoading(true);
            const response = await axios.post(Config.api_base + "/api/image/view", {
                id: id
            });
            let src = "";
            if (response.data.status === 404) {
                setImage("/assets/images/404.png");
            } else {
                const jsonResponse = response.data.image; // Buradaki base64Response, Worker'dan dönen JSON yanıtıdır
                const base64String = jsonResponse.image;

                src = `data:${response.data.mime};base64,${base64String}`;// 'data:image/png;base64,' + base64String; // Buradaki 'data:image/png;base64,' kısmını resmin tipine göre ayarlayın
                /*console.log(src)*/
                setImage(src);
                setNote(response.data.note);
                setTtl(response.data.ttl);
                //setLoading(false);
                setComments({
                    id: id,
                    ttl: response.data.ttl,
                    comments: response.data.comments
                });

                return response.data;
            }


        } catch (error) {
            //toast.error("Image removed");
            console.error('Hata:', error);
        }
    }
    const getComments = async () => {
        try {
            //setLoading(true);
            const response = await axios.get(Config.api_base + "/api/image/comments/" + id);

            if (response.data.status === 404) {
                console.log(404)
            } else {
                //console.log("saved",response.data);
                if (comments.comments?.length !== response.data?.length) {
                    setComments({
                        id: id,
                        ttl: ttl,
                        comments: response.data
                    });
                }
                return response.data;
            }


        } catch (error) {
            //toast.error("Image removed");
            console.error('Hata:', error);
        }
    }

    const guestNumberGen = () => {
        const guestNumStorage = Number(localStorage.getItem("guestNum"));
        if (guestNumStorage) {
            setGuestNum(guestNumStorage);
        } else {
            const num = Math.abs(Math.floor(Math.random() * (10000 - 99999 + 1)) + 10000);
            localStorage.setItem("guestNum", num.toString());
            setGuestNum(num);
        }
    }

    useEffect(() => {
        guestNumberGen();
        askView().then((res) => {
            //console.log(res);
            //console.log(res);
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let checkComments = setInterval(() => {
            getComments().then((res) => {
                if (comments.comments?.length !== res?.length) {
                    setComments({
                        id: id,
                        ttl: ttl,
                        comments: res
                    });
                }

                clearInterval(checkComments);
                setRefreshComments(!refreshComments);
            });
        }, 10 * 1000);

    }, [refreshComments]);

    const arraysEqual = (a, b) => {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (!Array.isArray(a) || !Array.isArray(b)) return false;
        if (a.length !== b.length) return false;

        for (let i = 0; i < a.length; i++) {
            if (JSON.stringify(a[i]) !== JSON.stringify(b[i])) return false;
        }

        return true;
    }


    return (
        <Container>
            <Row>
                <Col md={8} className={"p-0 pe-4"}>
                    {(image === "/assets/images/spinner.svg") ?
                        <p><img src={image} style={{width: "64px"}} alt={"Loading"}/><br/>Loading...</p> :
                        <img src={image} className={"img-fluid"} alt={"Uploaded"}/>}
                </Col>
                <Col className={"p-0"}>
                    {note && <Comment data={{type: "uploader", note, guestNum, ttl}}/>}

                    <Comments data={comments}/>

                </Col>
            </Row>

        </Container>
    )
}

export default View;
